import MsUrl from '../../../types/MsUrl'

const Php8: MsUrl = {
  idms: 'https://idms.staging.gappifyinc.com',
  txn_manager: 'https://ms-transaction-manager.staging.gappify.com',
  review_center: 'https://ms-review-center.staging.gappify.com',
  qje_manager: 'https://ms-qje.staging.gappify.com',
  taskmanager: 'https://ms-accrual-manager.staging.gappify.com',
  history_logs: 'https://ms-history.staging.gappify.com',
  analytics: 'https://ms-advanced-analytics.staging.gappify.com',
  token_duration: 1800,
  token_exp_allowance: 1,
  schedule_manager: 'https://ts-v2.staging.gappifyinc.com',
  schedule_manager_env: 'staging',
  search: 'https://search.staging.gappifyinc.com',
  period_manager: 'https://period-manager.staging.gappifyinc.com',
  settings: 'https://ms-settings.staging.gappify.com',
  gappify_api: 'https://internal-api.staging.gappifyinc.com'
} as const

export default Php8
